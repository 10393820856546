<template>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-wrap bg-primary-50 text-center mb-3 px-2 pt-2">
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT306 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT306 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT306i <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT306i }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT306 G2 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT306G2 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT326 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT326 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT330 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT330 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT334 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT334 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT405 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT405 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT405i <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT405i }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT406 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT406 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          SRT406i <span class="ml-8 font-bold text-primary-500 text-lg">{{ countSRT406i }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG Neo <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigNeo }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG Neo SRD <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigNeoSrd }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG Neo MD <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigNeoMd }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG Neo SRD MD <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigNeoSrdMd }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG One <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigOne }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG One SRD <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigOneSrd }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG One EX<span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigOneEx }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG Beacon <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigBeacon }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TWIG Beacon EX <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTwigBeaconEx }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          EMERIT WG100 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countEmeritWg100 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          TELTONIKA FMC003 <span class="ml-8 font-bold text-primary-500 text-lg">{{ countTeltonikaFMC003 }}</span>
        </div>
        <div class="w-1/2 lg:w-2/12 border-r border-secondary-400 font-serif font-semibold mb-2">
          4G Box <span class="ml-8 font-bold text-primary-500 text-lg">{{ count4GBox }}</span>
        </div>
      </div>
      <div class="w-full">
        <vue-good-table
          ref="orderDocumentTable"
          styleClass="vgt-table striped bordered condensed whitespace-nowrap"
          row-style-class="text-sm"
          :columns="columns"
          :rows="orders"
          :search-options="{
            enabled: true,
            placeholder: 'Sök',
          }"
          :pagination-options="{
            enabled: false,
          }"
          :line-numbers="true"
        >
          <div slot="table-actions">
            <button class="btn-green-outline mx-1" @click="importOrders()">
              <label class="cursor-pointer font-sans">
                {{ $t("import") }}
                <BaseIcon icon="cloud-upload-alt" class="ml-1" />
                <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref="importOrderFile" @change="importOrders()" class="hidden" />
              </label>
            </button>
            <button class="btn-blue-outline mx-1" @click="exportOrders()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'delete'">
              <button class="btn-red-outline" @click.prevent="deleteOrderPrompt(props.row)">
                <BaseIcon icon="trash" class="" />
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  title() {
    return `Inleveransdokument | SecurCloud`;
  },
  data() {
    return {
      user: this.$store.state.user,
      orders: [],
      devices: ["SRT306", "SRT306i", "SRT306 G2", "SRT326", "SRT330", "SRT334", "SRT405", "SRT405i", "SRT406", "SRT406i", "SRT430", "SRT278", "SRT341", "TWIG Neo", "TWIG Neo SRD", "TWIG Neo MD", "TWIG Neo SRD MD", "TWIG One", "TWIG One SRD", "TWIG One EX", "TWIG Beacon", "TWIG Beacon EX", "EMERIT WG100", "TELTONIKA FMC003", "4G Box"],
      countSRT306: "-",
      countSRT306i: "-",
      countSRT306G2: "-",
      countSRT326: "-",
      countSRT330: "-",
      countSRT334: "-",
      countSRT405: "-",
      countSRT405i: "-",
      countSRT406: "-",
      countSRT406i: "-",
      countSRT430: "-",
      countSRT278: "-",
      countSRT341: "-",
      countTwigNeo: "-",
      countTwigNeoSrd: "-",
      countTwigNeoMd: "-",
      countTwigNeoSrdMd: "-",
      countTwigOne: "-",
      countTwigOneSrd: "-",
      countTwigOneEx: "-",
      countTwigBeacon: "-",
      countTwigBeaconEx: "-",
      countEmeritWg100: "-",
      countTeltonikaFMC003: "-",
      count4GBox: "-",
    };
  },

  methods: {
    getOrderDocument() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/orders`)
        .then((response) => {
          this.orders = response.data.orders;
          this.countSRT306 = _.filter(this.orders, { type: "SRT306" }).length;
          this.countSRT306i = _.filter(this.orders, { type: "SRT306i" }).length;
          this.countSRT306G2 = _.filter(this.orders, { type: "SRT306 G2" }).length;
          this.countSRT326 = _.filter(this.orders, { type: "SRT326" }).length;
          this.countSRT330 = _.filter(this.orders, { type: "SRT330" }).length;
          this.countSRT334 = _.filter(this.orders, { type: "SRT334" }).length;
          this.countSRT405 = _.filter(this.orders, { type: "SRT405" }).length;
          this.countSRT405i = _.filter(this.orders, { type: "SRT405i" }).length;
          this.countSRT406 = _.filter(this.orders, { type: "SRT406" }).length;
          this.countSRT406i = _.filter(this.orders, { type: "SRT406i" }).length;
          this.countSRT430 = _.filter(this.orders, { type: "SRT430" }).length;
          this.countSRT278 = _.filter(this.orders, { type: "SRT278" }).length;
          this.countSRT341 = _.filter(this.orders, { type: "SRT341" }).length;
          this.countTwigNeo = _.filter(this.orders, { type: "TWIG Neo" }).length;
          this.countTwigNeoSrd = _.filter(this.orders, { type: "TWIG Neo SRD" }).length;
          this.countTwigNeoMd = _.filter(this.orders, { type: "TWIG Neo MD" }).length;
          this.countTwigNeoSrdMd = _.filter(this.orders, { type: "TWIG Neo SRD MD" }).length;
          this.countTwigOne = _.filter(this.orders, { type: "TWIG One" }).length;
          this.countTwigOneSrd = _.filter(this.orders, { type: "TWIG One SRD" }).length;
          this.countTwigOneEx = _.filter(this.orders, { type: "TWIG One EX" }).length;
          this.countTwigBeacon = _.filter(this.orders, { type: "TWIG Beacon" }).length;
          this.countTwigBeaconEx = _.filter(this.orders, { type: "TWIG Beacon EX" }).length;
          this.countEmeritWg100 = _.filter(this.orders, { type: "EMERIT WG100" }).length;
          this.countTeltonikaFMC003 = _.filter(this.orders, { type: "TELTONIKA FMC003" }).length;
          this.count4GBox = _.filter(this.orders, { type: "4G Box" }).length;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    importOrders() {
      if (this.$refs.importOrderFile.files.length > 0) {
        let file = this.$refs.importOrderFile.files[0];

        let reader = new FileReader();

        reader.onload = (evt) => {
          let workbook = XLSX.read(evt.target.result, { type: "binary" });
          let worksheet = workbook.Sheets[workbook.SheetNames[0]];

          let ordersArr = XLSX.utils.sheet_to_row_object_array(worksheet);

          let newOrders = [];
          ordersArr.forEach((el) => {
            let type = "",
              modelnumber = "",
              imei_number = "",
              program = "";

            if (this.devices.indexOf(el["Produkt"].toString().trim()) >= 0) type = el["Produkt"].toString().trim();
            if (el["Serienummer"]) modelnumber = el["Serienummer"].toString().trim();

            if (el["Imei-nummer"]) imei_number = el["Imei-nummer"].toString().trim();
            else if (el["IMEI"]) imei_number = el["IMEI"].toString().trim();

            if (el["Programmerad"]) program = el["Programmerad"].toString().trim();

            newOrders.push({
              type: type,
              modelnumber: modelnumber,
              imei_number: imei_number,
              program: program,
            });
          });

          this.isLoading = true;
          this.axios
            .post(`${process.env.VUE_APP_SERVER_URL}/order/bulk`, newOrders)
            .then(() => {
              //eslint-disable-line
              this.getOrderDocument();
              this.$refs.importOrderFile.value = null;
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              this.handleError(error);
            });
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };

        reader.readAsBinaryString(file);
      }
    },

    exportOrders() {
      const newColumns = this.orders.map((item) => ({
        Datum: this.formatDate(item.createdAt),
        Produkt: item.type,
        Serienummer: item.modelnumber,
        IMEI: item.imei_number,
        Programmerad: item.program,
      }));

      this.excelExport(newColumns, "Inleveransdokument", "Inleveransdokument");
    },

    deleteOrderPrompt(order) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">Radera larm med serienummer ${order.modelnumber}?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteOrder(order._id);
            },
          },
        ],
      });
    },

    async deleteOrder(_id) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/order/${_id}`);

        this.getOrderDocument();
        this.$modal.hide("dialog");
        this.handleSuccess("Larm har raderats");
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    columns() {
      return [
        { label: this.$t("stock_table.date"), field: "createdAt", formatFn: this.formatDate },
        { label: this.$t("stock_table.product"), field: "type", filterOptions: { enabled: true, placeholder: "Produkt", filterDropdownItems: this.devices } },
        { label: this.$t("stock_table.serial_number"), field: "modelnumber" },
        { label: this.$t("stock_table.imei"), field: "imei_number" },
        { label: this.$t("stock_table.programmed"), field: "program" },
        { label: "", field: "delete", sortable: false },
      ];
    },
  },
  created() {
    this.getOrderDocument();
    this.setPageTitle(`Inleveransdokument`, "order_document");
  },
};
</script>
